type Files = {
  fileName: string;
  slug?: string | "not-found";
};

const dataPPPK: Array<Files> = [
  { fileName: "Tryout Kompetensi Teknis", slug: "tryout-kompetensi-teknis" },
  { fileName: "Tryout TBS (Penalaran)", slug: "tryout-tbs" },
  { fileName: "Tryout Manajerial", slug: "tryout-manajerialb" },
  { fileName: "Tryout Sosio Kultural", slug: "tryout-sosio-kultural" },
  { fileName: "Tryout Wawancara", slug: "tryout-wawancara" },
];

const filesPPPK = (): Array<Files> => {
  return dataPPPK.map((e) => {
    const obj = {
      fileName: `${e.fileName}`,
      slug: `${e.slug}`,
    };
    return obj;
  });
};

const dataSKD: Array<Files> = [
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
  { fileName: "Paket SKD", slug: "paket-skd" },
];

const filesSKD = (): Array<Files> => {
  return dataSKD.map((e, i) => {
    const obj = {
      fileName: `${e.fileName} ${i + 1}`,
      slug: `${e.slug}-${i + 1}`,
    };
    return obj;
  });
};

const getRandomInt = (min: number, max: number) => {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min + 1)) + min;
};

type pplist = {
  peringkat: number;
  nomorTes: number;
  namaPeserta: string;
  skorTWK: number;
  skorTIU: number;
  skorTKP: number;
  totalSkor: number;
  status: string;
};

const dataList: pplist[] = [];
const setDataList = (): pplist[] => {
  for (let i = 0; i < 100; i++) {
    const twk: number = getRandomInt(1, 200);
    const tiu: number = getRandomInt(1, 100);
    const tkp: number = getRandomInt(1, 200);
    const total: number = twk + tiu + tkp;

    const obj = {
      peringkat: getRandomInt(1, 1000),
      nomorTes: getRandomInt(1, 10000),
      namaPeserta: "Test " + getRandomInt(1, 1000),
      skorTWK: twk,
      skorTIU: tiu,
      skorTKP: tkp,
      totalSkor: total,
      status: total > 350 ? "Lulus" : "Gagal",
    };
    dataList.push(obj);
  }

  return dataList;
};

export { Files, pplist, filesPPPK, filesSKD, setDataList };
