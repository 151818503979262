
import { defineComponent, ref, provide } from "vue";
import { Files, pplist, filesPPPK, filesSKD, setDataList } from "./form-komplain";
import { useRouter } from "vue-router";
import { helper as $h } from "@/utils/helper";
import { gpf } from "@/utils/global-functions";

import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor";
import BalloonEditor from "@ckeditor/ckeditor5-editor-balloon/src/ballooneditor";
import DocumentEditor from "@ckeditor/ckeditor5-editor-decoupled/src/decouplededitor";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import StrikethroughPlugin from "@ckeditor/ckeditor5-basic-styles/src/strikethrough";
import CodePlugin from "@ckeditor/ckeditor5-basic-styles/src/code";
import SubscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/subscript";
import SuperscriptPlugin from "@ckeditor/ckeditor5-basic-styles/src/superscript";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import EasyImage from "@ckeditor/ckeditor5-easy-image/src/easyimage";
import Font from "@ckeditor/ckeditor5-font/src/font";
import Heading from "@ckeditor/ckeditor5-heading/src/heading";
import HeadingButtonsUI from "@ckeditor/ckeditor5-heading/src/headingbuttonsui";
import Highlight from "@ckeditor/ckeditor5-highlight/src/highlight";

export default defineComponent({
  setup() {
    const salesReportFilter = ref("");
    const importantNotesRef = ref<HTMLElement>();
    const router = useRouter();
    let files: Array<Files> = [];
    let fSKD: Array<Files> = [];
    let dataTPPPK: any;

    provide("bind[importantNotesRef]", (el: HTMLElement) => {
      importantNotesRef.value = el;
    });

    const prevImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("prev");
    };
    const nextImportantNotes = () => {
      const el: any = importantNotesRef.value;
      el.tns.goTo("next");
    };

    const saveUrl = (data: any) => {
      dataTPPPK = data;
      // console.log('dataTPPPK', dataTPPPK);
      gpf.showModal("#modal-add-topik-kp");
    }
    const gotoTryoutExam = () => {
      // console.log('gotoTryoutExam ', dataTPPPK);
      gpf.hideModal("#modal-add-topik-kp");
      router.push({name: 'add-topik-kp-exam', params: dataTPPPK});
    }

    const getData = () => {
      files = filesPPPK();
      fSKD = filesSKD();
    };
    getData();

    const titleTable: Array<string> = [
      "No",
      "Tanggal",
      "Topik",
      "Status",
      "Update",
      "#"
    ];

    // BEGIN for CKEditor
    const date = ref("");
    const classicEditor = ClassicEditor;
    const inlineEditor = InlineEditor;
    const balloonEditor = BalloonEditor;
    const documentEditor = DocumentEditor;
    const simpleEditorConfig = {
      plugins: [
        ParagraphPlugin,
        BoldPlugin,
        UnderlinePlugin,
        ItalicPlugin,
        LinkPlugin,
      ],
      toolbar: {
        items: ["bold", "italic", "underline", "link"],
      },
    };
    const editorConfig = {
      cloudServices: {
        tokenUrl: "",
        uploadUrl: "",
      },
      plugins: [
        Font,
        EssentialsPlugin,
        BoldPlugin,
        UnderlinePlugin,
        StrikethroughPlugin,
        ItalicPlugin,
        LinkPlugin,
        ParagraphPlugin,
        CodePlugin,
        SubscriptPlugin,
        SuperscriptPlugin,
        EasyImage,
        Heading,
        HeadingButtonsUI,
        Highlight,
      ],
      toolbar: {
        items: [
          "fontSize",
          "fontFamily",
          "fontColor",
          "fontBackgroundColor",
          "bold",
          "italic",
          "underline",
          "strikethrough",
          "code",
          "subscript",
          "superscript",
          "link",
          "undo",
          "redo",
          "imageUpload",
          "highlight",
        ],
      },
    };
    const editorData = ref("<p>Content of the editor.</p>");
    const initDocumentEditor = (editor: any) => {
      const toolbarContainer = document.querySelector(
        ".document-editor__toolbar"
      );
      toolbarContainer?.appendChild(editor.ui.view.toolbar.element);
      const win: any = window;
      win.editor = editor;
    };
    // END for CKEditor

    return {
      salesReportFilter,
      prevImportantNotes,
      nextImportantNotes,
      files,
      saveUrl,
      gotoTryoutExam,
      fSKD,
      titleTable,

      // for CKEditor
      date,
      classicEditor,
      inlineEditor,
      balloonEditor,
      documentEditor,
      simpleEditorConfig,
      editorConfig,
      editorData,
      initDocumentEditor,
    };
  },
  data() {
    return {
      select: "paket-skd-1",
      dataList: [] as pplist[],

      // pagination
      minIndex: 0,
      maxPerPage: 5,
      maxIndex: 4,
      activePage: 0,
      numberOfPages: [],
      goToPageInput: 0,
      sortedData: [],
      searchActive: 0,
      formData: {},

      // another pagination
      paginate: {},
      currentPage: 1,
      pageSize: 10,
      maxPages: 3,
    }
  },
  methods: {
    init() {
      this.dataList = setDataList();
      // console.log('this.dataList ', this.dataList);
      this.getDataPaginate();
    },
    // START FUNCTION-PAGINATION-TABLE
    pagesCounter(itemLength: number) {
      let m = Math.ceil(itemLength / this.maxPerPage);
      if (m <= 1) {
        m = 1;
      }
      const nop: any = Array(m)
        .fill(0, 0)
        .map((x, i) => i);
      this.numberOfPages = nop;
    },
    openPage(selectedPage: number) {
      const page = selectedPage + 1;
      this.maxIndex = page * this.maxPerPage - 1;
      this.minIndex = page * this.maxPerPage - this.maxPerPage;
      this.activePage = selectedPage;
    },
    changeMaxPerPage(e: number) {
      const page = 1;
      this.maxPerPage = e;
      this.pagesCounter(this.dataList.length);
      this.maxIndex = page * this.maxPerPage - 1;
      this.minIndex = page * this.maxPerPage - this.maxPerPage;
      this.activePage = 0;
    },
    goToPage() {
      const page = this.goToPageInput - 1;
      if (
        typeof page === 'number' &&
        page <= this.numberOfPages.length - 1 &&
        page >= 0
      ) {
        this.openPage(page);
      }
    },
    // END FUNCTION-PAGINATION-TABLE

    getData(currentPage:number, pageSize:number, maxPages:number) {
      return $h.paginationLocal(this.dataList.length, currentPage, pageSize, maxPages, this.dataList);
    },
    getDataPaginate() {
      const dddlist = this.getData(this.currentPage, this.pageSize, this.maxPages);
      this.paginate = dddlist;
    },
    nextPage() {
      this.currentPage++;
      const dddlist = this.getData(this.currentPage, this.pageSize, this.maxPages);
      this.paginate = dddlist;
    },
    backPage() {
      this.currentPage--;
      const dddlist = this.getData(this.currentPage, this.pageSize, this.maxPages);
      this.paginate = dddlist;
    },
    lastPage(endIndex: number) {
      const dddlist = this.getData(endIndex + 1, this.pageSize, this.maxPages);
      this.paginate = dddlist;
    },
    firstPage(startIndex: number) {
      const dddlist = this.getData(startIndex + 1, this.pageSize, this.maxPages);
      this.paginate = dddlist;
    }
  },
  mounted() {
    // console.log('paginate data ', $h.paginate(setDataList().length, 1, 10, 3, setDataList()));
    this.init();
  }
});
